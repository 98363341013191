import React from "react";
import Img from "gatsby-image";

const PreviewCompatibleImage = ({ imageInfo, style = {}, alt = "" }) => {
  const imageStyle = { borderRadius: "0.25rem", ...style };
  if (!imageInfo) {
    return <div style={{ backgroundColor: "#bfbfbf", ...imageStyle }} />;
  }

  if (!!imageInfo && typeof imageInfo === "string")
    return <img style={imageStyle} src={imageInfo} alt={alt} />;

  const { childImageSharp, image } = imageInfo;

  let imageSharp = childImageSharp;
  if (!!image && !!image.childImageSharp) {
    imageSharp = image.childImageSharp;
  }

  if (imageSharp)
    return (
      <Img
        style={imageStyle}
        fluid={imageSharp.fluid}
        alt={alt}
        objectFit="cover"
        fadeIn={true}
        durationFadeIn={300}
      />
    );

  return null;
};

export default PreviewCompatibleImage;
