import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import SectionTitle from "../components/SectionTitle";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import AboutFrom from "../components/AboutForm";

export const AboutPageTemplate = ({
  subtitle,
  title,
  headings,
  image,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  // console.debug(headings);
  // console.debug(image);
  return (
    <div className="about-page">
      <div className="section container">
        <SectionTitle
          className="heading-title"
          heading={title}
          subHeading={subtitle}
        />

        <div className="headings-section columns">
          <div className="image-container">
            <div className="ratio-1-1">
              <PreviewCompatibleImage
                imageInfo={image}
                style={{ position: "absolute", borderRadius: "1rem" }}
              />
            </div>
          </div>
          <ReactMarkdown className="headings content">{headings}</ReactMarkdown>
        </div>

        <PageContent className="content" content={content} />
      </div>
      <div className="section container">
        <SectionTitle className="contact-title" heading="Get in touch" />
        <AboutFrom />
      </div>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <Layout title={page.frontmatter.title}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        subtitle={page.frontmatter.subtitle}
        title={page.frontmatter.title}
        headings={page.frontmatter.headings}
        image={page.frontmatter.image}
        content={page.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        subtitle
        title
        headings
        image
      }
    }
  }
`;
