import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import FormInputField from "./Form/FormInputField";
import FormTextAreaField from "./Form/FormTextAreaField";
import Form from "./Form/Form";
import FormSubmitButtonField from "./Form/FormSubmitButtonField";
import { encode } from "../utils/url";

import "react-toastify/dist/ReactToastify.min.css";

const AboutFrom = () => {
  const [formData, setFormData] = useState({});

  const reset = () => {
    const form = document.querySelector(".about-form");
    const inputs = form.querySelectorAll("input, textarea");
    inputs.forEach((i) => {
      i.value = "";
    });
    setFormData({});
  };

  const handleSubmit = (e) => {
    // console.log('hi');
    e.preventDefault();
    const form = e.target;
    console.debug(formData);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        reset();
        toast.success("Sent.");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed, please try again.");
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Form
      className="about-form"
      name="contact"
      action="/contact/thanks"
      honeypot="firstname"
      onHoneypotValueChange={handleChange}
      onSubmit={handleSubmit}
    >
      <FormInputField
        label="Full name"
        name="name"
        type="text"
        onChange={handleChange}
      />
      <FormInputField
        label="Email address"
        name="email"
        type="email"
        onChange={handleChange}
      />
      <FormInputField
        label="Where did you find me?"
        name="where-find-me"
        type="text"
        onChange={handleChange}
      />
      <FormInputField
        label="Reason of enquiry"
        name="enquiry-reason"
        type="text"
        onChange={handleChange}
      />
      <FormTextAreaField
        label="Message"
        name="message"
        type="textarea"
        onChange={handleChange}
      />

      <FormSubmitButtonField>Send message</FormSubmitButtonField>
      <ToastContainer />
    </Form>
  );
};

export default AboutFrom;
